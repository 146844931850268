export const AccademicYears = () => {
    const today = new Date();
    let year = today.getFullYear();
    year += 2;
    let year2 = year + 1;
    let sessions = [];
    for (let c = 0; c < 10; c++) {
        sessions.push(`${year - c} - ${year2 - c}`);
    }
    return sessions;
}

export const semestersNumber = () => {
    return ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
}
export const getRoles = () => {
    return ["1", "2", "3", "4"];
}

export const getLevels = () => {
    let result = []
    for (let i = 1; i <= 5; i++) {
        result.push(i * 100)
    }
    return result;
}

export const getStandardFees = () => {
    let result = [{
            name: "Frais de scolarité",
            value: "schoolFees"
        },
        {
            name: "Inscription/Réinscription",
            value: "registerFees"
        },
        {
            name: "Badge",
            value: "badge"
        },
        {
            name: "Travaux pratiques",
            value: "praticalWork"
        },
        {
            name: "Uniformes",
            value: "uniforms"
        },
        {
            name: "Visite médicale",
            value: "medicalVisit"
        },

    ]
    return result;
}